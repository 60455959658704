import axios from 'axios'
import { getAppApiUrl } from '../services/app-settings/app-settings'
import { getLinkToken } from '../services/auth'
import type { AxiosResponse } from 'axios'
import type { StatusCodes } from 'http-status-codes'

export const API_HEADERS = {
  authorization: 'Authorization',
  reCAPTCHAToken: 'x-recaptcha-token',
}

export const API = axios.create({ baseURL: getAppApiUrl() }) // http://localhost:58120

export function APIInterceptor() {
  API.interceptors.request.use(
    (config: any) => {
      const token = getLinkToken()
      if (token) {
        Object.assign(config.headers, {
          [API_HEADERS.authorization]: `Bearer ${token}`,
        })
      }
      return config
    },
    (error) => Promise.reject(error)
  )
}

export interface APIResponse<T> {
  statusCode: StatusCodes
  body: T
  status?: 'success' | 'error'
}

export function buildSuccessResponse(response: AxiosResponse) {
  return { statusCode: response.status, body: response.data }
}

/**
 * Asserts that a parameter is not null or undefined.
 * @throws {Error}
 */
export const assertParamExists = function ({
  functionName,
  paramName,
  paramValue,
}: {
  functionName: string
  paramName: string
  paramValue: unknown
}) {
  if (paramValue === null || paramValue === undefined) {
    throw new Error(`Required parameter ${paramName} was null or undefined when calling ${functionName}.`)
  }
}
