export const paths = {
  404: '*',
  APPLICANT_DETAILS: 'applicant/view',
  APPLICANT_PORTAL: 'applicantportal/:orgCode',
  CALENDAR: 'calendar',
  CASE: 'case/:caseId/:screenName',
  CREATE_CASE: 'applicant/case/new',
  EXTERNAL_APPLICATION: 'external-application',
  EXTERNAL_CONFIRMATION: 'confirmation',
  EXTERNAL_FORM: '/external-case/:caseId',
  EXTERNAL_PROFESSIONAL_VERIFICATION: 'verification/:orgCode',
  HOME: 'home',
  INTAKE: 'form',
  INTERNAL_APPLICATION: 'application',
  NEW_APPLICANT: 'applicant/new',
  OUTREACH: 'outreach',
  OUTREACH_EVENT: 'outreach/event',
  OUTREACH_EVENT_RECORDS: 'outreach/partner/records',
  PLAYGROUND: 'play',
  SCHEDULER: 'scheduler',
  OCR: 'ocr',
  SUPPLEMENTAL: '/case/:caseId/supplemental-interview',
  SETTINGS: 'settings',
}

export const pathIds = {
  404: 'not-found',
  APPLICANT_DETAILS: 'applicant',
  APPLICANT_PORTAL: 'applicant-portal',
  CALENDAR: 'calendar',
  CASE: 'case',
  CREATE_CASE: 'create-case',
  EXTERNAL_APPLICATION: 'external-application',
  EXTERNAL_CONFIRMATION: 'external-confirmation',
  EXTERNAL_FORM: 'external-form',
  EXTERNAL_PROFESSIONAL_VERIFICATION: 'external-professional-verification',
  HOME: 'home',
  INTAKE: 'intake-page',
  INTERNAL_APPLICATION: 'application',
  NEW_APPLICANT: 'new-contact',
  OUTREACH: 'outreach-page',
  OUTREACH_EVENT: 'outreach-event',
  OUTREACH_EVENT_RECORDS: 'outreach-partner-records',
  PLAYGROUND: 'play',
  SCHEDULER: 'scheduler',
  OCR: 'ocr',
  SUPPLEMENTAL: 'supplemental-interview',
  SETTINGS: 'settings',
  INTAKE_PAGE: 'intake',
  OUTREACH_PAGE: 'outreach',
}

export const pathTitles = {
  404: 'Not Found',
  APPLICANT_DETAILS: 'Applicant Details',
  APPLICANT_PORTAL: 'Applicant Portal',
  CALENDAR: 'Calendar',
  CASE: 'Case Details',
  CREATE_CASE: 'Create :caseType Case',
  EXTERNAL_APPLICATION: 'New Application',
  EXTERNAL_CONFIRMATION: 'Confirmation',
  EXTERNAL_FORM: 'External Form',
  EXTERNAL_PROFESSIONAL_VERIFICATION: 'Professional Verification',
  HOME: 'Home',
  INTAKE: 'Intake',
  INTERNAL_APPLICATION: 'New Application',
  NEW_APPLICANT: 'Create Applicant',
  OUTREACH: 'Outreach',
  OUTREACH_EVENT: 'Outreach Event',
  OUTREACH_EVENT_RECORDS: 'Outreach Partner Records',
  PLAYGROUND: 'Play Ground',
  SCHEDULER: 'Scheduler',
  OCR: 'Scan Documents',
  SUPPLEMENTAL: 'Supplemental Interview',
  SETTINGS: 'Settings',
}

export const externalLinks = [
  'external-application',
  'verification',
  'confirmation',
  'applicantportal',
  'external-case',
  'play',
]
