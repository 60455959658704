import React from 'react'
import { css, Global } from '@emotion/react'

const fontSize = '0.875rem'
const borderRadius = '0.25rem'

const globalStyles = css`
  body {
    font-family: Avenir, system-ui, -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, ubuntu, cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.5;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    background: #e5e5e5;
    padding: 0.5rem 0.25rem;
    border-radius: 0.25rem;
  }

  * {
    box-sizing: border-box;
    letter-spacing: 0.4px;
  }

  // custom styles

  .mtm-link {
    color: #1890ff !important;
  }

  .ant-table-tbody > tr .mtm-sortable-column {
    padding-left: 1rem;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter {
    display: block !important;
  }

  .rcp-table-wrapper .ant-table-thead .mtm-icon {
    display: none !important;
  }

  .ant-picker-dropdown-range {
    padding-top: 30px;
  }

  .mtm-input-alternate {
    font-size: ${fontSize};
    color: #000000;
    background: #ffffff;
    border-radius: ${borderRadius};
    padding: 0.375rem 0.75rem;
    border: 1px solid #d9d9d9;

    &:hover,
    &:focus,
    &:active {
      border-color: #40a9ff;
    }
  }

  .ant-input {
    color: #000000;
    background: #ffffff;
    border-radius: ${borderRadius};
    padding: 0.375rem 0.75rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${borderRadius};
    padding: 0.125rem 0.75rem;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: unset;
  }

  textarea.ant-input {
    border-radius: ${borderRadius};
    padding: 0.375rem 0.75rem;
  }

  .cb-form-input__instruction {
    padding: 0.5rem 0.75rem;
  }

  .ant-picker {
    border-radius: ${borderRadius};
    padding: 0.375rem 0.75rem;
  }

  .ant-transfer-list {
    border-radius: ${borderRadius};
  }

  .ant-transfer-list-header {
    border-radius: ${borderRadius} ${borderRadius} 0 0;
  }

  .ant-checkbox-wrapper {
    width: max-content;
  }

  .ant-checkbox-inner {
    border-radius: ${borderRadius};
  }

  .ant-checkbox-checked::after {
    border-radius: ${borderRadius};
  }

  .ant-modal-header {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .ant-modal-content {
    border-radius: 0.5rem;
  }
}
`

export const GlobalStyles = () => <Global styles={globalStyles} />
