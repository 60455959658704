import type { ReactNode } from 'react'
import React from 'react'
import type { WithChildren } from '../types/common'

const buildContextTree = (branches: (({ children }: WithChildren) => JSX.Element)[], children: ReactNode) => {
  if (branches.length === 0) return <>{children}</>
  const BaseContext = branches[0] as any
  return <BaseContext>{buildContextTree(branches.slice(1), children)}</BaseContext>
}

export const ContextTree = ({
  children,
  branches = [],
}: WithChildren<{ branches?: (({ children }: WithChildren) => JSX.Element)[] }>) => buildContextTree(branches, children)
