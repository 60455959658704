import React, { lazy, Suspense } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorBoundary } from 'react-error-boundary'
import { Dynamic, PageSpinner, TimeZoneProvider } from '@mtm/components'
import { ContextTree } from './components/context-tree'
import { ErrorFallback } from './components/feedback/error-fallback/error-fallback'
import { GlobalStyles } from './components/theme/global'
import { RcpThemeProvider } from './components/theme/theme'
import { GlobalContextProvider } from './context/global-context'
import { APIInterceptor } from './lib/api'
import { QueryProvider } from './lib/query-client'
import { isExternalLink } from './routes/helpers'
import '@fullcalendar/react/dist/vdom'

// lazy import named export
const InternalApp = lazy(() => import('./apps/internal-app').then((module) => ({ default: module.InternalApp })))
const ExternalApp = lazy(() => import('./apps/external-app').then((module) => ({ default: module.ExternalApp })))

APIInterceptor()

export default function App() {
  const apps = {
    internal: InternalApp,
    external: ExternalApp,
  }

  const type = isExternalLink() ? 'external' : 'internal'

  return (
    <>
      <GlobalStyles />
      <RcpThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryProvider>
            <ContextTree branches={[TimeZoneProvider, GlobalContextProvider]}>
              <Suspense fallback={<PageSpinner />}>
                <Dynamic component={apps[type]} props={{}} />
              </Suspense>
            </ContextTree>
            <ReactQueryDevtools initialIsOpen={true} />
          </QueryProvider>
        </ErrorBoundary>
      </RcpThemeProvider>
    </>
  )
}
