import type { FC } from 'react'
import React from 'react'
import { QueryClient, QueryClientProvider, useMutation, useQuery } from '@tanstack/react-query'
import type { APIResponse } from './api'
import type { MutationFunction, QueryFunction, QueryKey } from '@tanstack/react-query'

/**
 * React query client provider
 * Set refetchOnWindowFocus as process.env.NODE_ENV !== 'production' once stabilized
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const QueryProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

export interface RcpQueryProps<T> {
  name: any
  queryFn: QueryFunction<APIResponse<T>, QueryKey>
  options?: any
}

export function useRcpQuery<T>({ name, queryFn, options }: RcpQueryProps<T>) {
  const query = Array.isArray(name) ? name : [name]

  return useQuery<APIResponse<T>, APIResponse<string>>(query, queryFn, {
    ...options,
    retry: options?.retry ?? 0,
    refetchOnWindowFocus: false,
  })
}

export interface RcpMutationProps<T> {
  mutationFn: MutationFunction<APIResponse<T>, any>
  options?: any
}

export function useRcpMutation<T>({ mutationFn, options }: RcpMutationProps<T>) {
  return useMutation<APIResponse<T>, APIResponse<string>>(mutationFn, options)
}
