import React from 'react'
import { Divider, Result } from '@mtm/components'
import logo from '../../../assets/images/mtm-link-banner.png'
import { getStrings } from '../../../constants/strings'
import { ExclamationCircleIcon } from '../../svgs/exclamation-circle'
import { ErrorCode, IconContainer } from './error-fallback.styles'

type ErrorFallbackProps = {
  error?: Error
  resetErrorBoundary?: () => void
}

export const ErrorFallback = ({ error = new Error() }: ErrorFallbackProps) => {
  const strings = getStrings(['GENERIC_ERROR_DESCRIPTION_TEXT', 'GENERIC_ERROR_HEADING_TEXT'])
  return (
    <Result
      status="error"
      title={strings.GENERIC_ERROR_HEADING_TEXT}
      subTitle={strings.GENERIC_ERROR_DESCRIPTION_TEXT}
      icon={
        <IconContainer>
          <img src={logo} alt="mtm-link" />
          <Divider />
          <ExclamationCircleIcon />
        </IconContainer>
      }
    >
      {error && error.message && process.env.NODE_ENV === 'development' && <ErrorCode>{error.message}</ErrorCode>}
    </Result>
  )
}
