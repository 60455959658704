import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useReducer } from 'react'
import { isJson } from '../helpers/validate-json'
import type { CaseType, Employee, OrgListItem } from '../types'
import type { IOrganization, OrgAttribute } from '../types/organization'
import type { GenericAction, GenericContextType } from './types'

export enum GlobalActions {
  SetCaseTypes = 'SetCaseTypes',
  SetEmployees = 'SetEmployees',
  SetOrgList = 'SetOrgList',
  SetOrganizations = 'SetOrganizations',
  SetOrgAttributes = 'SetOrgAttributes',
  Reset = 'Reset',
}

export type State = {
  caseTypes: CaseType[]
  employees: Employee[]
  organizations: IOrganization[]
  orgList: OrgListItem[]
  orgAttributes: OrgAttribute[]
}

type Action =
  | { type: GlobalActions.Reset }
  | GenericAction<GlobalActions.SetCaseTypes, CaseType[]>
  | GenericAction<GlobalActions.SetEmployees, Employee[]>
  | GenericAction<GlobalActions.SetOrgList, OrgListItem[]>
  | GenericAction<GlobalActions.SetOrganizations, IOrganization[]>
  | GenericAction<GlobalActions.SetOrgAttributes, { orgId: number; attributes: any[] }[]>

const initialState: State = {
  caseTypes: [],
  employees: [],
  organizations: [],
  orgList: [],
  orgAttributes: [],
}

export const GlobalContext = createContext<GenericContextType<State, Action>>(undefined)

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case GlobalActions.Reset:
      return initialState
    case GlobalActions.SetCaseTypes:
      return { ...state, caseTypes: action.payload }
    case GlobalActions.SetEmployees:
      return { ...state, employees: action.payload }
    case GlobalActions.SetOrgList:
      return { ...state, orgList: action.payload }
    case GlobalActions.SetOrganizations:
      return { ...state, organizations: action.payload }
    case GlobalActions.SetOrgAttributes: {
      const updatedOrgAttributes = action.payload.map((orgAttr) => ({
        orgId: orgAttr.orgId,
        attributes: orgAttr.attributes.reduce((acc: any, curr: any) => {
          const { attributeName, attributeValue } = curr
          if (isJson(attributeValue)) {
            Object.assign(acc, { [attributeName]: JSON.parse(attributeValue) })
          }
          return acc
        }, {}),
      }))
      return {
        ...state,
        orgAttributes: updatedOrgAttributes,
      }
    }
    default:
      return state
  }
}

export function GlobalContextProvider({ children }: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

function useGlobalContext() {
  const context = useContext(GlobalContext)

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider')
  }

  return context
}

export function useGlobalSelector() {
  const { state } = useGlobalContext()

  return state
}

export function useGlobalDispatch() {
  const { dispatch } = useGlobalContext()

  return dispatch
}

// Additional custom selectors
export function useOrganizationSelector(value: number | string, key: 'id' | 'code' = 'id') {
  const {
    state: { orgList },
  } = useGlobalContext()
  if (key === 'id') {
    return orgList.find((org) => org.id === Number(value))
  }
  return orgList.find((org) => org.orgCode === value)
}

export function useCaseTypeSelector(value: number | string, key: 'id' | 'name' = 'id') {
  const {
    state: { caseTypes },
  } = useGlobalContext()
  if (key === 'id') {
    return caseTypes.find((caseType) => caseType.id === value)
  }
  return caseTypes.find((caseType) => caseType.name === value)
}

export function useOrganizationMatch(toMatch: string) {
  const {
    state: { organizations },
  } = useGlobalContext()
  return organizations.some((org) => org.organizationCode === toMatch)
}

export function useOrgAttributeSelector(orgId?: number) {
  const {
    state: { orgAttributes },
  } = useGlobalContext()
  const attributes = orgAttributes.find((attr) => attr.orgId === orgId)
  return attributes ? attributes.attributes : undefined
}
