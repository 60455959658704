export enum ErrorKey {
  ADD_NOTE = 'ADD_NOTE',
  APPLICANT_HISTORY_ERROR = 'APPLICANT_HISTORY_ERROR',
  COMPLETE_APPLICANTS_NEEDS_APPLICATION_MAILED_ERROR = 'COMPLETE_APPLICANTS_NEEDS_APPLICATION_MAILED_ERROR',
  CREATE_APPLICANT_ERROR = 'CREATE_APPLICANT',
  CREATE_APPLICATION = 'CREATE_APPLICATION',
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  CREATE_APPOINTMENT_SLOT = 'CREATE_APPOINTMENT_SLOT',
  CREATE_CASE = 'CREATE_CASE',
  CREATE_NEW_CASE_ERROR = 'CREATE_NEW_CASE_ERROR',
  CREATE_SCHEDULE_EXCEPTIONS = 'CREATE_SCHEDULE_EXCEPTIONS',
  CREATE_SCHEDULES = 'CREATE_SCHEDULES',
  DELETE_APPLICANT_ERROR = 'DELETE_APPLICANT',
  DELETE_APPLICANT_HAS_CASES = 'DELETE_APPLICANT_HAS_CASES',
  DELETE_CASE_ERROR = 'DELETE_CASE_ERROR',
  DOWNLOAD_APPLICANT_FILE = 'DOWNLOAD_APPLICANT_FILE',
  DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR',
  DOWNLOAD_IN_PROGRESS = 'DOWNLOAD_IN_PROGRESS',
  DOWNLOAD_ORG_FILE = 'DOWNLOAD_ORG_FILE',
  EDIT_APPOINTMENT = 'EDIT_APPOINTMENT',
  FETCH_APPOINTMENT_SLOTS = 'FETCH_APPOINTMENT_SLOTS',
  FETCH_DOCUMENT_ERROR = 'FETCH_DOCUMENT_ERROR',
  FETCH_FORM_CATALOG_IDS_ERROR = 'FETCH_FORM_CATALOG_IDS_ERROR',
  FETCH_MASTER_CATALOG_RESULTS_ERROR = 'FETCH_MASTER_CATALOG_RESULTS_ERROR',
  FETCH_MOBILITY_AIDS_FOR_ORGANIZATION = 'FETCH_MOBILITY_AIDS_FOR_ORGANIZATION',
  GENERAL_ERROR = 'GENERAL_ERROR',
  GENERAL_SAVE_ERROR = 'GENERAL_SAVE_ERROR',
  GENERATE_ELIGIBILITY_LETTER = 'GENERATE_ELIGIBILITY_LETTER',
  GET_APPLICANT = 'GET_APPLICANT',
  GET_APPLICANT_CASES = 'GET_APPLICANT_CASES',
  GET_APPLICANT_FILE = 'GET_APPLICANT_FILE',
  GET_APPLICANT_RECORDS = 'GET_APPLICANT_RECORDS',
  GET_APPLICANT_HISTORY = 'GET_APPLICANT_HISTORY',
  GET_APPLICATION = 'GET_APPLICATION',
  GET_APPLICATION_FORM = 'GET_APPLICATION_FORM',
  GET_APPLICATION_SCREEN = 'GET_APPLICATION_SCREEN',
  GET_APPOINTMENT_TYPES_BY_CASE_TYPE = 'GET_APPOINTMENT_TYPES_BY_CASE_TYPE',
  GET_APPOINTMENTS_BY_MAE = 'GET_APPOINTMENTS_BY_MAE',
  GET_BLANK_PDF_FORMS = 'GET_BLANK_PDF_FORMS',
  GET_CASE = 'GET_CASE',
  GET_CASE_TYPES = 'GET_CASE_TYPES',
  GET_EMPLOYEES = 'GET_EMPLOYEES',
  GET_FORM_DETAILS = 'GET_FORM_DETAILS',
  GET_INTAKE_FORM_DATA = 'GET_INTAKE_FORM_DATA',
  GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS',
  GET_MAE_OPTIONS = 'GET_MAE_OPTIONS',
  GET_NOTE_DETAILS = 'GET_NOTE_DETAILS',
  GET_NOTES = 'GET_NOTES',
  GET_OPTIONS = 'GET_OPTIONS',
  GET_ORGANIZATION_CASE_TYPES = 'GET_ORGANIZATION_CASE_TYPES',
  GET_ORGANIZATION_INFO = 'GET_ORGANIZATION_INFO',
  GET_ORGANIZATIONS = 'GET_ORGANIZATIONS',
  GET_RECENT_ITEMS = 'GET_RECENT_ITEMS',
  GET_RIDE_ID = 'GET_RIDE_ID',
  GET_SCHEDULER_SLOTS = 'GET_SCHEDULER_SLOTS',
  GET_SCREEN = 'GET_SCREEN',
  GET_SCREEN_CONFIG = 'GET_SCREEN_CONFIG',
  GET_SCREEN_CONFIG_BY_ID = 'GET_SCREEN_CONFIG_BY_ID',
  GET_CASE_HISTORY_ERROR = 'GET_CASE_HISTORY_ERROR',
  MY_DASHBOARD_VIEWS = 'MY_DASHBOARD_VIEWS',
  OUTREACH_CREATE_ERROR = 'OUTREACH_CREATE_ERROR',
  OUTREACH_DELETE_ERROR = 'OUTREACH_DELETE_ERROR',
  OUTREACH_EVENT_CREATE_ERROR = 'OUTREACH_EVENT_CREATE_ERROR',
  OUTREACH_EVENT_DELETE_ERROR = 'OUTREACH_EVENT_DELETE_ERROR',
  OUTREACH_EVENT_DUPLICATE_ERROR = 'OUTREACH_EVENT_DUPLICATE_ERROR',
  OUTREACH_EVENT_GET_ERROR = 'OUTREACH_EVENT_GET_ERROR',
  OUTREACH_EVENT_RECORDS_GET_ERROR = 'OUTREACH_EVENT_RECORDS_GET_ERROR',
  OUTREACH_EVENT_UPDATE_ERROR = 'OUTREACH_EVENT_UPDATE_ERROR',
  OUTREACH_GET_ERROR = 'OUTREACH_GET_ERROR',
  OUTREACH_ORGANIZATION_DUPLICATE_ERROR = 'OUTREACH_ORGANIZATION_DUPLICATE_ERROR',
  OUTREACH_UPDATE_ERROR = 'OUTREACH_UPDATE_ERROR',
  ORG_ATTRIBUTE_ERROR = 'ORG_ATTRIBUTE_ERROR',
  PDF_DOCUMENT = 'PDF_DOCUMENT',
  PROFESSIONAL_POST = 'PROFESSIONAL_POST',
  PROFESSIONAL_VERIFY = 'PROFESSIONAL_VERIFY',
  RECAPTCHA = 'RECAPTCHA',
  REMOVE_SCHEDULE = 'REMOVE_SCHEDULE',
  REMOVE_SCHEDULE_EXCEPTION = 'REMOVE_SCHEDULE_EXCEPTION',
  SAVE_APPLICANT_ERROR = 'SAVE_APPLICANT',
  SAVE_CASE_ERROR = 'SAVE_CASE_ERROR',
  SAVE_FORM_DATA_ERROR = 'SAVE_FORM_DATA_ERROR',
  SEARCH_APPOINTMENT_SLOTS = 'SEARCH_APPOINTMENT_SLOTS',
  SEARCH_ERROR = 'SEARCH_ERROR',
  SEARCH_SCHEDULE_EXCEPTIONS = 'SEARCH_SCHEDULE_EXCEPTIONS',
  SEARCH_SCHEDULES = 'SEARCH_SCHEDULES',
  SUBMIT_INTAKE_FORM_ERROR = 'SUBMIT_INTAKE_FORM_ERROR',
  TRANSLATE_LANGUAGES_ERROR = 'TRANSLATE_LANGUAGES_ERROR',
  TRIGGER_RIDER360_EXPORT_ERROR = 'TRIGGER_RIDER360_EXPORT_ERROR',
  UPDATE_CASE_APPROVAL_STATUS = 'UPDATE_CASE_APPROVAL_STATUS',
  UPDATE_COUNTER_LOG_ON_APPLICATION_COMPLETE = 'UPDATE_COUNTER_LOG_ON_APPLICATION_COMPLETE',
  UPDATE_COUNTER_LOG_ON_APPLICATION_STATUS = 'UPDATE_COUNTER_LOG_ON_APPLICATION_STATUS',
  UPDATE_COUNTER_LOG_ON_INTERVIEW_COMPLETE = 'UPDATE_COUNTER_LOG_ON_INTERVIEW_COMPLETE',
  UPDATE_NOTE = 'UPDATE_NOTE',
  UPDATE_SCHEDULE_EXCEPTIONS = 'UPDATE_SCHEDULE_EXCEPTIONS',
  UPDATE_SCHEDULES = 'UPDATE_SCHEDULES',
  UPLOAD_FILE = 'UPLOAD_FILE',
}

export enum SuccessKey {
  CREATE_APPLICANT_SUCCESS = 'CREATE_APPLICANT_SUCCESS',
  DELETE_APPLICANT_SUCCESS = 'DELETE_APPLICANT_SUCCESS',
  GENERAL_SAVE_SUCCESS = 'GENERAL_SAVE_SUCCESS',
  OUTREACH_CREATE_SUCCESS = 'OUTREACH_CREATE_SUCCESS',
  OUTREACH_DELETE_SUCCESS = 'OUTREACH_DELETE_SUCCESS',
  OUTREACH_EVENT_CREATE_SUCCESS = 'OUTREACH_EVENT_CREATE_SUCCESS',
  OUTREACH_EVENT_DELETE_SUCCESS = 'OUTREACH_EVENT_DELETE_SUCCESS',
  OUTREACH_EVENT_UPDATE_SUCCESS = 'OUTREACH_EVENT_UPDATE_SUCCESS',
  OUTREACH_UPDATE_SUCCESS = 'OUTREACH_UPDATE_SUCCESS',
  SAVE_APPLICANT_SUCCESS = 'SAVE_APPLICANT_SUCCESS',
  SAVE_CASE_SUCCESS = 'SAVE_CASE_SUCCESS',
  SAVE_FORM_DATA_SUCCESS = 'SAVE_FORM_DATA_SUCCESS',
  SAVE_SUPPLEMENTAL_SUCCESS = 'SAVE_SUPPLEMENTAL_SUCCESS',
  SUBMIT_INTAKE_FORM_SUCCESS = 'SUBMIT_INTAKE_FORM_SUCCESS',
}
