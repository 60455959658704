import styled from '@emotion/styled'

export const IconContainer = styled.div`
  font-size: 2rem;
  font-weight: 700;

  svg {
    fill: ${(props) => props.theme.colors.error};
    height: 96px;
    width: auto;
  }
`

export const ErrorCode = styled.code`
  display: block;
  margin-top: 2rem;
  padding: 1rem 2rem;
`
