import type { OcrApiConfig } from '../../types/ocr'

declare global {
  interface Window {
    // add you custom properties and methods
    __env: object
  }
}
const browserWindow = window || {}
const appSettings: any = browserWindow.__env || {}

export const getOauthCodeFlowSettings = () => appSettings.OAUTH_CODE_FLOW_SETTINGS

export const getAppApiUrl = () => appSettings.APP_API_URL

export const getApiMockEnabled = () => appSettings.API_MOCK_ENABLED

export const getAppMapping = () => appSettings.appsMapping

/**
 * Function to get the reCAPTCHA sitekey for the current environment
 * @returns APP_RECAPTCHA_SITEKEY - the reCAPTCHA sitekey for the current environment
 */
export const getReCAPTCHASiteKey = (): string => appSettings.APP_RECAPTCHA_SITEKEY

export const getDocURL = () => appSettings.APP_DOCUMENT_URL

export const getFeatureFlags = () => appSettings.FEATURE_FLAGS

export const getOcrApiConfig = (): OcrApiConfig | undefined => appSettings.OCR_API_CONFIG ?? undefined
export const getOrgManagementUrl = () => appSettings.ORG_MANAGEMENT.url
