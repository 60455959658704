import { Log, UserManager } from 'oidc-client'
import { message } from '@mtm/components'
import { getString } from '../../constants/strings'
import { getOauthCodeFlowSettings } from '../app-settings/app-settings'

// NOTE: These key and value must be the same for all the MTM Web apps
const ACTIVE_USER = 'MTM-Active-User'
export let user: any
const POST_SIGNIN_REDIRECT_URL_PATH_KEY = 'POST_LOGIN_URL_PATH_KEY'

const userManager = new UserManager(getOauthCodeFlowSettings())
Log.logger = console // TODO Logging for development only
handlerUserLoadedEvent()

handlerUserSignoutEvent()

async function signinRedirectInternal() {
  _setPostSigninRedirectUrlPath()

  await userManager.signinRedirect({
    extraQueryParams: {
      state_timestamp: new Date().getTime(),
    },
  })
}

export async function signinRedirect() {
  return await signinRedirectInternal()
}

export function getPostSigninRedirectUrlPath() {
  return sessionStorage.getItem(POST_SIGNIN_REDIRECT_URL_PATH_KEY)
}

export function removePostSigninRedirectUrlPath() {
  sessionStorage.removeItem(POST_SIGNIN_REDIRECT_URL_PATH_KEY)
}

function _setPostSigninRedirectUrlPath() {
  let currentPath = window.location.hash || window.location.pathname
  currentPath = currentPath === '/' ? '' : currentPath
  if (currentPath.startsWith('#/')) {
    currentPath = currentPath.substring(2)
  } else if (currentPath.startsWith('/')) {
    currentPath = currentPath.substring(1)
  }

  const queryString = window.location.search
  if (queryString) {
    currentPath = `${currentPath}${queryString}`
  }
  sessionStorage.setItem(POST_SIGNIN_REDIRECT_URL_PATH_KEY, currentPath)
}

export async function signinRedirectCallback() {
  user = await userManager.signinRedirectCallback()
  sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user))

  return user
}

export function userAvailable() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return JSON.parse(sessionStorage.getItem(ACTIVE_USER)!)
}

export async function getUser() {
  if (user) {
    sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user))

    return user
  }
  user = await userManager.getUser()
  sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user))

  return user
}

function handlerUserLoadedEvent() {
  userManager.events.addUserLoaded(() => {
    userManager.getUser().then((userResp: any) => {
      user = userResp
      sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user))
    })
  })
}

export async function renewToken() {
  try {
    user = await userManager.signinSilent()
    sessionStorage.setItem(ACTIVE_USER, JSON.stringify(user))
    message.destroy()
  } catch (error) {
    message.error(getString('NETWORK_ISSUE_MESSAGE'), 0)
  }

  return user
}

export async function oAuthLogout() {
  user = await getUser()

  if (user) {
    await userManager.signoutRedirect({ id_token_hint: user.id_token })
    await userManager.removeUser()
    user = null
  }
}

function handlerUserSignoutEvent() {
  userManager.events.addUserSignedOut(async () => {
    user = await getUser()

    if (user) {
      await userManager.signoutRedirect({ id_token_hint: user.id_token })
      await userManager.removeUser()
      user = null
    }
  })
}

export function isIdpRedirect() {
  return window.location.href.includes('/#/redirect')
}
