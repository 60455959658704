import { externalLinks, paths } from './constants'

export const getRoutePathByName = (name: keyof typeof paths) => {
  const routePath = paths[name]
  if (!routePath) {
    throw new Error(`Route path for ${name} not found`)
  }
  if (routePath.startsWith('/')) {
    return routePath
  }
  return `/${routePath}`
}

/**
 * Checks if the current page is an external link
 * @returns true if the current page is an external link
 */
export const isExternalLink = () => externalLinks.some((link) => window.location.href.includes(link))
