import React from 'react'
import createCache from '@emotion/cache'
import { CacheProvider, ThemeProvider } from '@emotion/react'
import colors from '../../assets/styles/_colors.scss'
import type { WithChildren } from '../../types/common'

const theme = {
  colors: {
    ...colors,
  },
}

const myCache = createCache({
  key: 'mtm-rcp',
})

export const RcpThemeProvider = ({ children }: WithChildren) => (
  <CacheProvider value={myCache}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </CacheProvider>
)
